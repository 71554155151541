<template>
    <div class="primary-marker">P</div>
</template>

<script>
export default {
    name: 'PrimaryMarker',
};
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
.primary-marker {
    background-color: $primary;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    text-align: center;
    color: $white;
    line-height: 1.65rem;
    font-size: 1rem;
}
</style>
